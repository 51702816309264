/* src/components/ChapterPage.css */

.chapter-page-container {
    font-family: 'Poppins', sans-serif;
    padding: 20px;
    background: linear-gradient(135deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
    color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.disclaimer {
    position: absolute;
    top: 200px;
    right: 50px;
    font-size: 0.6rem;
    color: white;
}


.chapter-header {
    text-align: center;
    margin-bottom: 40px;
    padding-top: 60px;
    animation: fadeInDown 1s ease-out;
}

.chapter-header h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.chapter-content {
    display: flex;
    flex: 1;
    gap: 40px;
    animation: fadeInUp 1s ease-out;
}

.chapter-menu {
    width: 25%;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.chapter-menu h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #fff;
}

.chapter-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.chapter-menu li {
    margin-bottom: 10px;
}

.chapter-menu a {
    text-decoration: none;
    color: white;
    display: block;
    padding: 12px 18px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-size: 1rem;
}

.chapter-menu a:hover, .chapter-menu a.active {
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
}

.chapter-details {
    width: 75%;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chapter-details h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.questions .question-answer {
    margin-bottom: 20px;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    animation: fadeInUp 0.8s ease-out;
}

.questions h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #ff9f43;
}

.questions p {
    font-size: 1.2rem;
    color: #fff;
    line-height: 1.5;
    text-align: justify;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .chapter-content {
        flex-direction: column;
    }

    .chapter-menu {
        width: 100%;
        padding: 10px;
    }

    .chapter-details {
        width: 100%;
        padding: 15px;
    }

    .chapter-menu h2 {
        display: none; /* Hide the "Chapters" title on mobile */
    }

    .chapter-menu ul {
        display: none; /* Hide the list on mobile */
    }

    .chapter-menu select {
        display: block;
        width: 100%;
        padding: 10px;
        font-size: 1rem;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.1);
        color: white;
        border: none;
        outline: none;
    }

    .chapter-menu select option {
        background-color: rgba(63, 94, 251, 1); /* Match the gradient background color */
        color: white;
    }
}

/* Only visible on mobile, hidden on desktop */
.chapter-menu select {
    display: none;
}

/* Animation */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

