.contact-container {
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    padding-top: 100px; /* Adjusting for the fixed header */
    background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
    color: white;
    min-height: 100vh;
}

.contact-hero {
    padding: 50px 0;
    text-align: center;
}

.contact-hero h1 {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    animation: fadeInUp 1.5s ease-out;
}

.contact-hero p {
    font-size: 1.5rem;
    margin-bottom: 40px;
    animation: fadeInUp 2s ease-out;
}

.contact-content {
    display: flex;
    flex-direction: column; /* Mobile-friendly layout */
    justify-content: space-between;
    padding: 30px;
    margin: 0 auto;
    max-width: 1000px;
    border-radius: 10px;
    animation: fadeInUp 2.5s ease-out;
    background: rgba(255, 255, 255, 0.1);
}

.contact-details, .contact-form {
    background: rgba(255, 255, 255, 0.2);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    width: 100%; /* Ensure full width on mobile */
}

.contact-details h2, .contact-form h2 {
    margin-bottom: 20px;
    font-size: 2rem;
    color: #ff9f43; /* Accent color to match the theme */
    text-align: center;
}

.contact-details p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    color: white;
}

.google-map {
    width: 100%;
    height: 250px;
    border-radius: 8px;
    overflow: hidden;
}

.contact-form {
    color: white;
}

.contact-form .form-group {
    margin-bottom: 20px;
}

.contact-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: white;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-color: #ff9f43;
    outline: none;
    box-shadow: 0 0 10px rgba(255, 159, 67, 0.5);
}

.contact-form button.submit-btn {
    width: 100%;
    padding: 12px;
    background-color: #ff9f43;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    margin-top: 10px;
    transition: background-color 0.3s ease;
}

.contact-form button.submit-btn:hover {
    background-color: #e68a29;
}

@media (min-width: 768px) {
    .contact-content {
        flex-direction: row;
    }
    
    .contact-details, .contact-form {
        width: 48%;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
