/* src/index.css */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial, sans-serif';
}

.App {
  text-align: center;
}
