/* src/components/Programs.css */
.programs-container {
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    padding-top: 100px; /* Adjusting for the fixed header */
    background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
    color: white;
    min-height: 100vh;
}

.programs-hero {
    padding: 50px 0;
    text-align: center;
}

.programs-hero h1 {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    animation: fadeInUp 1.5s ease-out;
}

.programs-hero p {
    font-size: 1.5rem;
    margin-bottom: 40px;
    animation: fadeInUp 2s ease-out;
}

.programs-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
}

.program-section {
    flex: 1 1 48%;
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    animation: fadeInUp 2.5s ease-out;
}

.program-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center;
    animation: fadeInUp 1.5s ease-out;
}

.program-description {
    font-size: 1.2rem;
    margin-bottom: 20px;
    text-align: center;
    animation: fadeInUp 2s ease-out;
}

.program-features {
    list-style-type: none;
    padding: 0;
    text-align: center;
    font-size: 1.2rem;
    animation: fadeInUp 2.5s ease-out;
}

.program-features li {
    margin: 10px 0;
}

.enroll-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: #ff9f43;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.enroll-button:hover {
    background-color: #e68a29;
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .program-section {
        flex: 1 1 100%;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
