/* src/components/Header.css */
.header-section {
    background: transparent;
    padding: 15px 0;
    z-index: 1000;
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    height: 50px;
    margin-right: 20px;
}

.nav-link {
    font-size: 16px;
    margin: 0 20px;
    color: #fff;
}

.nav-link:hover, .nav-item.active .nav-link {
    color: #ff9f43;
}

.enroll-btn {
    padding: 8px 20px;
    font-weight: bold;
    background-color: #ff9f43;
    color: #fff;
    border: none;
    transition: background-color 0.3s ease;
}

.enroll-btn:hover {
    background-color: #e68a29;
}
