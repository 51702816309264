.about-container {
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    padding-top: 100px; /* Adjusting for the fixed header */
    background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
    color: white;
    min-height: 100vh;
}

.about-hero {
    padding: 50px 0;
    text-align: center;
}

.about-hero h1 {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    animation: fadeInUp 1.5s ease-out;
}

.about-hero h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    animation: fadeInUp 1.5s ease-out;
}

.quote {
    font-size: 1.8rem;
    margin-bottom: 30px;
    animation: fadeInUp 2s ease-out;
    font-family: 'Indie Flower', cursive;
}

.about-intro {
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto 20px;
    animation: fadeInUp 2.5s ease-out;
    text-align: justify; /* Justify the text for a cleaner look */
}

.vision-mission-section {
    padding: 50px 0;
    background: rgba(255, 255, 255, 0.1);
    text-align: center;
}

.vision-title, .mission-title {
    font-size: 2rem;
    margin-bottom: 10px;
    animation: fadeInUp 1.5s ease-out;
}

.vision-text, .mission-text {
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto 30px;
    animation: fadeInUp 2s ease-out;
    text-align: justify; /* Justify the text for a cleaner look */
}

.founder-section {
    padding: 50px 0;
    color: white;
    text-align: left;
    background: rgba(255, 255, 255, 0.1);
}

.founder-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px; /* Added padding for a more spacious layout */
}

.founder-image-container {
    flex: 1;
    display: inline-block;
    padding: 10px;
    max-width: 200px;
    margin-right: 30px;
}

.founder-image {
    border-radius: 50%;
    width: 100%;
    height: auto;
    object-fit: cover;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add some shadow to the image */
}

.founder-info {
    flex: 2;
    padding: 20px;
}

.founder-info h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    animation: fadeInUp 2.5s ease-out;
}

.founder-info p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    animation: fadeInUp 3s ease-out;
}

.founder-info h4 {
    font-size: 1.5rem;
    margin-top: 10px;
    animation: fadeInUp 3.5s ease-out;
}

.teachers-section {
    padding: 50px 0;
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
}

.teachers-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px; /* Added padding for a more spacious layout */
}

.teacher-image-container {
    flex: 1;
    display: inline-block;
    padding: 10px;
    max-width: 200px;
    margin-right: 30px;
}

.teacher-image {
    border-radius: 50%;
    width: 100%;
    height: auto;
    object-fit: cover;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add some shadow to the image */
}

.teacher-info {
    flex: 2;
    text-align: left;
    padding: 20px;
}

.teacher-info h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    animation: fadeInUp 1.5s ease-out;
}

.teacher-info p {
    font-size: 1.2rem;
    animation: fadeInUp 2s ease-out;
}

.teacher-list {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
}

.teacher-button {
    background-color: transparent;
    border: 2px solid #ff9f43;
    color: white;
    padding: 10px 20px;
    margin: 10px 0;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.teacher-button:hover {
    background-color: #ff9f43;
    transform: scale(1.05);
}

.teacher-button.active {
    background-color: #ff9f43;
}

@media (max-width: 768px) {
    .founder-layout, .teachers-layout {
        flex-direction: column;
        text-align: center; /* Center align for mobile */
    }

    .founder-image-container, .teacher-image-container {
        margin: 0 auto 20px;
    }

    .founder-info, .teacher-info {
        text-align: center;
        padding: 0;
    }

    .teacher-list {
        margin-left: 0;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
