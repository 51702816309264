.home-container {
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
}

.header-section {
    background: transparent;
    padding: 15px 0;
    z-index: 1000;
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    height: 50px;
    margin-right: 20px;
}

.nav-link {
    font-size: 16px;
    margin: 0 20px;
    color: #fff;
    transition: color 0.3s ease;
}

.nav-link:hover {
    color: #ff9f43;
}

.enroll-btn {
    padding: 8px 20px;
    font-weight: bold;
    background-color: #ff9f43;
    color: #fff;
    border: none;
    transition: background-color 0.3s ease;
}

.enroll-btn:hover {
    background-color: #e68a29;
}

.hero {
    background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.hero-content {
    z-index: 10;
    position: relative;
}

.main-title {
    font-size: 4rem;
    font-weight: 800;
    color: #fff;
    margin-bottom: 20px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
    animation: fadeInUp 1.5s ease-out;
}

.sub-title {
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 30px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
    animation: fadeInUp 2s ease-out;
}

/* Navbar-specific styling */
.navbar-toggler {
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-width='2' linecap='round' linejoin='round' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-collapse {
    justify-content: center;
}

@media (max-width: 991px) {
    .navbar-collapse {
        background-color: rgba(63, 94, 251, 1); /* Match the hero background */
        padding: 10px;
        border-radius: 8px;
    }

    .nav-item {
        margin-bottom: 10px;
    }

    .enroll-btn {
        width: 100%;
    }

    .navbar-toggler {
        margin-left: auto;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
