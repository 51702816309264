.subject-page-container {
    font-family: 'Poppins', sans-serif;
    padding: 60px 20px;
    background: linear-gradient(135deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
    color: white;
    min-height: 100vh;
}

.subject-header {
    text-align: center;
    margin-bottom: 40px;
    padding-top: 100px;
}

.subject-header h1 {
    font-size: 3rem;
    margin-bottom: 10px;
    animation: fadeInDown 1.5s ease;
}

.subject-description {
    font-size: 1.2rem;
    margin-bottom: 30px;
    animation: fadeInUp 2s ease;
}

.search-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    animation: fadeInDown 1.5s ease;
}

.search-input {
    width: 100%;
    max-width: 600px;
    padding: 15px 20px;
    border: none;
    border-radius: 10px;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
    color: #333;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.search-input:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
}

.chapter-list-container {
    display: flex;
    justify-content: center;
}

.chapter-list {
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.chapter-link {
    display: block;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 15px 20px;
    border-radius: 10px;
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.chapter-link:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-3px);
}

.search-results-container {
    margin-top: 20px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    animation: fadeInUp 2s ease;
}

.search-result-item {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 15px 20px;
    border-radius: 10px;
    margin-bottom: 15px;
    animation: fadeInUp 1.5s ease;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.search-result-item h3 {
    margin-top: 0;
    color: white;
    font-size: 1.4rem;
    margin-bottom: 10px;
    text-align: right;
    width: 100%;
}

.search-result-item p {
    margin: 5px 0;
    color: white;
    font-size: 1rem;
    text-align: left;
}

.search-result-item p strong {
    color: white;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
